import React from "react";
import {useState }from "react";

import { useFormik} from "formik";
import axios from "axios";

function CreateQuestion() {
  const [isUnlisted, setIsUnlisted] = useState(false);

  const firstOptionValue = document.getElementById("firstoption")
    ? document.getElementById("firstoption").value
    : "";
  const secondOptionValue = document.getElementById("secondoption")
    ? document.getElementById("secondoption").value
    : "";
  const thirdOptionValue = document.getElementById("thirdoption")
    ? document.getElementById("thirdoption").value
    : "";
  const fourthOptionValue = document.getElementById("fourthoption")
    ? document.getElementById("fourthoption").value
    : "";

  const formik = useFormik({
    initialValues: {
      question: "",
      description: "",
      type: "",
      category: "",
      difficulty: "",
      options: ["", "", "", ""],
      correctOption: "",
      tags: "",
      time: 10,
      points: 10,
      logo: "",
    },

    onSubmit: async (values,{ resetForm }) => {
      // Body create
      let apiBody = {
        ...values,
        tags: values.tags.split(","),
        time: values.time,
        correctOption: document.getElementById("correctOption").value,
        isUnlisted:isUnlisted
      };
      // doing api call
      console.log(JSON.stringify(apiBody));
      var config = {
        method: "post",
        url: "https://new.quizwalla.in/api/v2/admin/question/create",
        headers: {
          "Content-Type": "application/json",
          "qw-header":
            "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a",
        },
        data: JSON.stringify(apiBody),
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          alert("question created");

            // Reset only question and options
      resetForm({
        values: {
          ...values, // spread the existing values
          question: "", // reset question
          options: ["", "", "", ""] // reset options
        },
      });
        })

        
        .catch(function (error) {
          alert("something went wrong");

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    },
  });

  const dropdownOptions = [
    { value: true, label: "true" },
    { value: false, label: "false" },
  ];
  
  const handleIsUnlistedChange = (e) => {
    setIsUnlisted(e.target.value);
  };

  return (
    <div className="flex flex-col justify-center items-center border-2 py-8 px-6 shadow rounded-lg sm:px-10 bg-blue-400">

      <form
        className="w-full max-w-lg font-bold text-1xl text-white outline-4"
        style={{ zoom: 0.67 }}
        onSubmit={formik.handleSubmit}
      >
        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-12 ml-20"
              htmlFor="question"
            >
              Question
            </label>
          </div>

          <div className="md:w-full">
            <textarea
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-10 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="text"
              id="question"
              name="question"
              onChange={formik.handleChange}
              value={formik.values.question}
              placeholder="question"
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="description"
            >
              Description
            </label>
          </div>
          <div className="md:w-2/3">
            <textarea
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="text"
              id="description"
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
              placeholder="description"
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="firstoption"
            >
              First Option
            </label>
          </div>

          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="firstoption"
              name="options[0]"
              onChange={formik.handleChange}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="secondoption"
            >
              Second Option
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="secondoption"
              name="options[1]"
              onChange={formik.handleChange}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="thirdoption"
            >
              Third Option
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="thirdoption"
              name="options[2]"
              onChange={formik.handleChange}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="fourthoption"
            >
              Fourth Option
            </label>
          </div>

          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="fourthoption"
              name="options[3]"
              onChange={formik.handleChange}
            ></input>
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              class="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="category"
            >
              Category
            </label>
          </div>

          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="text"
              id="category"
              name="category"
              onChange={formik.handleChange}
              value={formik.values.category}
              placeholder="category"
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="difficulty"
            >
              Difficulty
            </label>
          </div>

          <div className="md:w-2/3">
            <select
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              name="difficulty"
              onChange={formik.handleChange}
            >
              <option value="">Select level</option>
              <option value="easy">easy</option>
              <option value="medium">medium</option>
              <option value="hard">hard</option>
            </select>
          </div>
        </div>

        {/* <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="type"
            >
              Type
            </label>
          </div>

          <div className="md:w-2/3">
            <select
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              name="type"
              onChange={formik.handleChange}
            >
              <option value="">type</option>
              <option value="mcq">mcq</option>
            </select>
          </div>
        </div> */}

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="correctOption"
            >
              Correct Option
            </label>
          </div>
          <div className="md:w-2/3">
            <select
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="correctOption"
              onChange={formik.handleChange}
            >
              <option value="">corectoption</option>
              <option value={firstOptionValue}>{firstOptionValue}</option>
              <option value={secondOptionValue}>{secondOptionValue}</option>
              <option value={thirdOptionValue}>{thirdOptionValue}</option>
              <option value={fourthOptionValue}>{fourthOptionValue}</option>
            </select>
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="prizemoney"
            >
              Points
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="points"
              name="points"
              type="number"
              min="0"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.points}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="time"
            >
              Time(in sec)
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="time"
              name="time"
              type="number"
              min="0"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.time}
            />
          </div>
        </div>
        <span className="text-sm ml-60 text-lime-800">
          Note: separate tags using comma
        </span>
        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="expirytime"
            >
              Tags<br></br>
            </label>
          </div>

          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="tags"
              name="tags"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.tags}
              placeholder="linux,os,kernel"
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="imageLink"
            >
              Logo
            </label>
          </div>

          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="text"
              id="logo"
              name="logo"
              onChange={formik.handleChange}
              value={formik.values.imageLink}
              placeholder="imageLink"
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
        <div className="md:w-1/3">
          <label className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4">
            Is Unlisted
          </label>
        </div>
        <div className="md:w-2/3">
          <select
            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            value={isUnlisted}
            onChange={handleIsUnlistedChange}
            name="isUnlisted"

          >
            {dropdownOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>

        <button
          className=" ml-60 shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded "
          type="submit"
        >
          Create Question
        </button>
      </form>
    </div>
  );
}
export default CreateQuestion;
