import React from "react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

const AllDeposit = () => {
  const [user, setUser] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [startIndex, setStartIndex] = useState(1); // Track the start index for the current page

  const fetchFunction = async () => {
    // doing api call
    var config = {
      method: "get",
      url: `https://new.quizwalla.in/api/v2/admin/addmoney?page=${page}&itemsPerPage=${itemsPerPage}`,
      headers: {
        "qw-header":
          "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a",
      },
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setUser(response.data.addmoney);
        setStartIndex((page - 1) * itemsPerPage + 1); // Update the start index based on the current page and items per page

        // alert("summary get successfully");
      })
      .catch(function (error) {
        alert("something went wrong");

        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  };

  useEffect(() => {
    fetchFunction();

    return () => {
      fetchFunction();
    };
  }, [page, itemsPerPage]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
  };

  const getAggregate = () => {
    const totalAmount = user
      .filter((res) => res.responseDetails.message === "SUCCESS")
      .reduce((a, b) => a + b.amount, 0);
    return totalAmount;
  };

  if (user?.length) {
    return (
      <div className="flex flex-col">
        {/* <Header category="Page" title="All deposit" /> */}
        <div className="overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <div className="overflow-hidden border rounded-lg">
              <table
                className="min-w-full divide-y divide-gray-400 rounded-3xl"
                style={{ zoom: 0.5 }}
              >
                <thead className="bg-red-50">
                  <tr>
                    <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Serial
                    </th>
                    {Object.keys(user[0]).map((res, index) => {
                      return (
                        <th
                          key={index}
                          scope="col"
                          className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {res.slice(0, 7)}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {user.map((res, index) => {
                    return (
                      <tr key={index} className="bg-white hover:bg-gray-100">
                        <td className="px-4 py-2 border-2  hover:bg-fuchsia-100">
                          {startIndex + index}{" "}
                          {/* Display the increasing index */}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          {res?._id}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {res?.merchTxnId}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {res?.merchTxnDate}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {res?.mobile}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          {res?.amount}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {res?.responseDetails.message}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {new Date(res?.createdAt).toLocaleString()}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-4">
          <button
            style={{ zoom: 0.7 }}
            onClick={(event) => handlePageChange(event, page - 1)}
            disabled={page === 1}
            className="p-2 rounded-full bg-gray-300 hover:bg-gray-400 focus:outline-none focus:shadow-outline text-blue-500 -mx-2 -my-2 inline-block"
          >
            Previous
          </button>

          <div className="mx-2"></div>
          <button
            style={{ zoom: 0.7 }}
            onClick={(event) => handlePageChange(event, page + 1)}
            disabled={user?.length < itemsPerPage}
            className="p-2 rounded-full bg-gray-300 hover:bg-gray-400 focus:outline-none focus:shadow-outline text-blue-500 -mx-2 -my-2 inline-block"
          >
            Next
          </button>
        </div>

        <div className="flex justify-center mt-4">
          <label className="mx-2 font-bold" style={{ zoom: 0.7 }}>
            Items per page:
          </label>
          <select
            style={{ zoom: 0.7 }}
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            className="p-2 bg-gray-300 rounded-full hover:bg-gray-400 focus:outline-none focus:shadow-outline -mx-2 -my-2 inline-block"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>

        <div class="mt-8">
          <h4 class="text-xl font-bold text-gray-900 text-center">
            Total Amount : success only
          </h4>
          <h5 class="text-lg font-semibold text-gray-500 text-center">
            {getAggregate()}
          </h5>
          <div class="text-center">
            <div class="flex justify-center">
              <div class="text-4xl font-bold text-gray-900">
                <b class="animate-pulse">{getAggregate()}</b>
              </div>
              <div class="text-2xl font-bold text-gray-500">
                <b>INR</b>
              </div>
            </div>
            <div class="h-10 w-10 rounded-full bg-green-500 mx-auto"></div>
          </div>
        </div>
      </div>
    );
  }
};

export default AllDeposit;
