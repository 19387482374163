import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AllUser = () => {
  let navigate = useNavigate();
  const [reportedUsers, setReportedUsers] = useState(new Set());

  const [user, setUser] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUser, setFilteredUser] = useState([]);

  const [startIndex, setStartIndex] = useState(1); // Track the start index for the current page
  const handleSearchQueryChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };
  const filterUser = () => {
    const filteredData = user.filter((res) => {
      const { uid, name, mobile, pan, accountNumber, ifsc, upi } = res;
      const lowerCaseQuery = searchQuery.toLowerCase();

      return (
        String(uid).toLowerCase().includes(lowerCaseQuery) ||
        String(name).toLowerCase().includes(lowerCaseQuery) ||
        String(mobile).toLowerCase().includes(lowerCaseQuery) ||
        String(pan).toLowerCase().includes(lowerCaseQuery) ||
        String(accountNumber).toLowerCase().includes(lowerCaseQuery) ||
        String(ifsc).toLowerCase().includes(lowerCaseQuery) ||
        String(upi).toLowerCase().includes(lowerCaseQuery)
      );
    });

    setFilteredUser(filteredData.length ? filteredData : user);
  };

  useEffect(() => {
    const fetchFunction = async () => {
      try {
        const response = await axios.get(
          `https://new.quizwalla.in/api/v2/admin/user?page=${page}&itemsPerPage=${itemsPerPage}`,
          {
            headers: {
              "qw-header": "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a",
            },
          }
        );
        setUser(response.data?.users);
        setStartIndex((page - 1) * itemsPerPage + 1);
      } catch (error) {
        console.log("Error fetching user data:", error);
      }
    };
    fetchFunction();
  }, [page, itemsPerPage]);

  useEffect(() => {
    filterUser();
  }, [user, searchQuery]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
  };

  const updateCoinHandler = (mobile) => {
    navigate("/UpdateCoin", { state: mobile });
  };

  // const reportUser = async (mobile1) => {
  //   try {
  //     const response = await axios.post(
  //       `https://new.quizwalla.in/api/v2/admin/user/update`,
  //       { mobile: mobile1, isReported: true },
  //       {
  //         headers: {
  //           "qw-header":
  //             "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a",
  //         },
  //       }
  //     );

  //     if (response.status >= 200) {
  //       alert(response.data.message);
  //     }
  //   } catch (error) {
  //     console.log("Error while reporting user :", error);
  //   }
  // };

  // const UnreportUser = async (mobile2) => {
  //   try {
  //     const response = await axios.post(
  //       `https://new.quizwalla.in/api/v2/admin/user/update`,
  //       { mobile: mobile2, isReported: false },
  //       {
  //         headers: {
  //           "qw-header":
  //             "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a",
  //         },
  //       }
  //     );

  //     if (response.status >= 200) {
  //       alert(response.data.message);
  //     }
  //   } catch (error) {
  //     console.log("Error while reporting user :", error);
  //   }
  // };

  const reportUser = async (mobile1) => {
    try {
      const response = await axios.post(
        `https://new.quizwalla.in/api/v2/admin/user/update`,
        { mobile: mobile1, isReported: true },
        {
          headers: {
            "qw-header":
              "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a",
          },
        }
      );

      if (response.status >= 200) {
        alert(response.data.message);
        setReportedUsers((prevSet) => new Set(prevSet.add(mobile1)));
      }
    } catch (error) {
      console.log("Error while reporting user :", error);
    }
  };

  const UnreportUser = async (mobile2) => {
    try {
      const response = await axios.post(
        `https://new.quizwalla.in/api/v2/admin/user/update`,
        { mobile: mobile2, isReported: false },
        {
          headers: {
            "qw-header":
              "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a",
          },
        }
      );

      if (response.status >= 200) {
        alert(response.data.message);
        setReportedUsers((prevSet) => {
          const updatedSet = new Set(prevSet);
          updatedSet.delete(mobile2);
          return updatedSet;
        });
      }
    } catch (error) {
      console.log("Error while unreporting user :", error);
    }
  };

  // if (user.length) {

  if (!user || user.length === 0) {
    return <div>Loading...</div>; // Or any other loading indicator
  }
  return (
    <div className="flex flex-col">
      <div className="flex justify-center items-center mt-2 space-x-2">
        <input
          style={{ zoom: 0.7 }}
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchQueryChange}
          className="px-2 py-1 border border-gray-300 rounded focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        />

        <button
          style={{ zoom: 0.7 }}
          onClick={(event) => handlePageChange(event, page - 1)}
          disabled={page === 1}
          className="px-2 py-1 rounded-full bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-blue-500 focus:ring-offset-2 text-blue-500"
        >
          Previous
        </button>

        <span className="mr-1" style={{ zoom: 0.7 }}>
          Page:
        </span>
        <input
          type="number"
          value={page}
          onChange={(event) => setPage(parseInt(event.target.value))}
          className="px-2 py-1 border border-gray-300 rounded focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        />

        <button
          style={{ zoom: 0.7 }}
          onClick={(event) => handlePageChange(event, page + 1)}
          disabled={user?.length < itemsPerPage}
          className="px-2 py-1 rounded-full bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-blue-500 focus:ring-offset-2 text-blue-500"
        >
          Next
        </button>

        <label className="mr-1 font-bold" style={{ zoom: 0.7 }}>
          Items per page:
        </label>
        <select
          style={{ zoom: 0.7 }}
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          className="px-2 py-1 bg-gray-300 rounded-full hover:bg-gray-400 focus:outline-none focus:ring-blue-500 focus:ring-offset-2"
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={100}>100</option>

          <option value={500}>500</option>
          <option value={1000}>1000</option>
          <option value={10000}>10000</option>
        </select>
      </div>

      <div className="overflow-x-auto">
        <div className="p-1.5 w-full inline-block align-middle">
          <div className="overflow-hidden border rounded-lg">
            <table
              className="min-w-full divide-y divide-gray-200 sm:divide-y-0"
              style={{ zoom: 0.42 }}
            >
              <thead className="bg-red-50">
                <tr>
                  <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Serial
                  </th>
                  {Object.keys(user[0]).map((res, index) => {
                    if (
                      res !== "avatar" &&
                      res !== "details" &&
                      res !== "badges" &&
                      res !== "stats" &&
                      res !== "categoryPreferences" &&
                      res !== "controlCredentials" &&
                      res !== "poolEntries" &&
                      res !== "isReported" &&
                      res !== "notificationToken"
                    ) {
                      return (
                        <th
                          key={index}
                          scope="col"
                          className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {res === "walletCredentials" ? "coins" : res}
                        </th>
                      );
                    }
                  })}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {filteredUser.map((res, index) => {
                  return (
                    <tr key={index} className="bg-white hover:bg-gray-100">
                      <td className="px-4 py-2 border-2  hover:bg-fuchsia-100">
                        {startIndex + index}{" "}
                        {/* Display the increasing index */}
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        {res?._id}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {res?.type}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {res?.uid}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {res?.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {res?.mobile}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {res?.email}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {res?.score}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {JSON.stringify(res?.paymentCredentials)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {res?.walletCredentials?.coins}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {new Date(res?.createdAt).toLocaleString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <button
                          className="shadow bg-blue-700 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                          onClick={(e) => {
                            e.preventDefault();
                            updateCoinHandler(res.mobile);
                          }}
                        >
                          Add Coin
                        </button>
                      </td>
                      {/* <td className="px-6 py-4 whitespace-nowrap">
                        <button
                          className="shadow bg-red-700 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                          onClick={(e) => {
                            e.preventDefault();
                            reportUser(res.mobile);
                          }}
                        >
                          Report User
                        </button>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <button
                          className="shadow bg-red-700 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                          onClick={(e) => {
                            e.preventDefault();
                            UnreportUser(res.mobile);
                          }}
                        >
                          Unreport User
                        </button>
                      </td> */}
                      <td className="px-6 py-4 whitespace-nowrap">
                        <button
                          className={`shadow ${
                            reportedUsers.has(res.mobile)
                              ? "bg-blue-500 text-white"
                              : "bg-red-700 hover:bg-purple-400"
                          } focus:shadow-outline focus:outline-none font-bold py-2 px-4 rounded`}
                          onClick={(e) => {
                            e.preventDefault();
                            if (reportedUsers.has(res.mobile)) {
                              UnreportUser(res.mobile);
                            } else {
                              reportUser(res.mobile);
                            }
                          }}
                        >
                          {reportedUsers.has(res.mobile)
                            ? "Reported"
                            : "Report User"}
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllUser;
