import React from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useLocation } from "react-router-dom";

const UpdateCoin = () => {
  const location = useLocation();

  const formik = useFormik({
    initialValues: {
      mobile: location.state,
      coins: 0,
    },
    onSubmit: async (values) => {
      // Body create
      let apiBody = {
        ...values,
        type: "INCREMENT",
      };

      // doing api call
      var config = {
        method: "post",
        url: "https://new.quizwalla.in/api/v2/admin/user/update",
        headers: {
          "Content-Type": "application/json",
          "qw-header":
            "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a",
        },
        data: JSON.stringify(apiBody),
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          alert("user coin updated");
        })
        .catch(function (error) {
          alert("something went wrong");

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    },
  });
  return (
    <div className=" flex flex-col justify-center items-center border-2 bg-gradient-to-r from-indigo-500 ...Name py-8 px-6 shadow rounded-lg sm:px-10 ">
      <form
        className="w-full max-w-lg font-bold text-1xl text-white outline-4 "
        onSubmit={formik.handleSubmit}
      >
        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="mobile"
            >
              Mobile
            </label>
          </div>
          <div class="md:w-2/3">
            <input
              type="number"
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              name="mobile"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.mobile}
              readOnly
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="updateAmount"
            >
              coins
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="updateAmount"
              name="coins"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.updateAmount}
            />
          </div>
        </div>

        <button
          className=" ml-60 shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded "
          type="submit"
        >
          Add Coin
        </button>
      </form>
    </div>
  );
};

export default UpdateCoin;
