import React, { useState, useEffect } from "react";
import axios from "axios";
import logoutImage from "../data (1)/data/logout.png";
import { Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";

import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [pools, setPools] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [userQuestion, setUserQuestion] = useState(0);

  const [metricsData, setMetricsData] = useState([]);
  const [poolmetrics, setpoolmetrics] = useState([]);
  const [homepagemetrics, sethomepagemetrics] = useState([]);
  let navigate = useNavigate();

  const onLogout = () => {
    localStorage.clear();
    // console.log( localStorage.removeItem('Token'))
    navigate("/");
    window.location.reload(false);
  };

  Chart.register(CategoryScale);

  async function fetchPools() {
    try {
      const response = await axios.get(
        "https://new.quizwalla.in/api/v2/admin/pool/active",
        {
          headers: {
            "qw-header":
              "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a",
          },
        }
      );

      setPools(response.data.active);

      const response2 = await axios.get(
        "https://new.quizwalla.in/api/v2/admin/metrics",
        {
          headers: {
            "qw-header":
              "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a",
          },
        }
      );
      setMetricsData(response2.data.metrics.signupmetrics);
      setpoolmetrics(response2.data.metrics.poolplayermetrics);
      sethomepagemetrics(response2.data.metrics.homepagemetrics);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchUserCount() {
    try {
      const response = await axios.get(
        "https://new.quizwalla.in/api/v2/admin/users/length",
        {
          headers: {
            "qw-header":
              "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a",
          },
        }
      );

      setUserCount(response.data.count);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchQuestionCount() {
    try {
      const response = await axios.get(
        "https://new.quizwalla.in/api/v2/admin/perday/questions-created",
        {
          headers: {
            "qw-header":
              "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a",
          },
        }
      );

      console.log("API count:", response.data.count);

      setUserQuestion(response.data.data.count);
    } catch (error) {
      console.error(error);
    }
  }
  async function fetchMetricsData() {
    try {
      const response = await axios.get(
        "https://new.quizwalla.in/api/v2/admin/metrics",
        {
          headers: {
            "qw-header":
              "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a",
          },
        }
      );
      setMetricsData(response.data.metrics.signupmetrics); // Assuming the response data is an array
    } catch (error) {
      console.error(error);
    }
  }

  // useEffect(() => {
  //   fetchMetricsData();
  // }, []);

  useEffect(() => {
    fetchPools();
    // fetchMetricsData()
  }, []);

  const onGetUserCount = () => {
    fetchUserCount();
  };
  const onGetQuestionCount = () => {
    fetchQuestionCount();
  };

  return (
    <>
      <div class="mt-24 " style={{ zoom: 0.6 }}>
        <div class="flex justify-between items-start mb-4">
          <div class="text-right">
            <button
              onClick={onLogout}
              class="rounded-full shadow-lg animate-pulse"
            >
              <img src={logoutImage} alt="Logout" class="w-8 h-10" />
            </button>
          </div>
    
          
        </div>

        <div class="mt-8 mx-4 md:mx-8">
    <button onClick={onGetUserCount} class="bg-gradient-to-r hover:bg-gradient-to-r from-blue-700 to-purple-700 text-white font-bold py-2 px-4 rounded-full shadow-lg animate-pulse">
        Get User Count
    </button>

    <div class="mt-4">
        <p class="text-lg text-gray-800">
            <span class="inline-block bg-gradient-to-r from-red-500 to-blue-500 text-white py-1 px-2 ml-2 rounded-full shadow-lg animate-scale-up text-4xl font-extrabold">
                {userCount}
            </span>
        </p>
    </div>
</div>

<div class="mt-8 mx-4 md:mx-8">
    <button onClick={onGetQuestionCount} class="bg-gradient-to-r hover:bg-gradient-to-r from-blue-700 to-purple-700 text-white font-bold py-2 px-4 rounded-full shadow-lg animate-pulse">
        Total Question Count Today
    </button>

    <div class="mt-4">
        <p class="text-lg text-gray-800">
            <span class="inline-block bg-gradient-to-r from-red-500 to-blue-500 text-white py-1 px-2 ml-2 rounded-full shadow-lg animate-scale-up text-4xl font-extrabold">
                {userQuestion}
            </span>
        </p>
    </div>
</div>

<div class="mx-4 md:mx-8 my-4 md:my-8 w-full md:w-4/5 lg:w-2/3 xl:w-1/2 2xl:w-3/8 bg-gradient-to-r from-indigo-100 to-blue-100 p-8 rounded-2xl shadow-2xl transform hover:scale-105 transition-transform duration-300 overflow-hidden">
    <h2 class="text-3xl font-bold text-center mb-8 text-indigo-700">
        Most Active Pools
    </h2>
    <div class="bg-white p-4 rounded-xl shadow-md overflow-x-auto">
        <table class="min-w-full divide-y divide-indigo-300">
            <thead>
                <tr>
                    {[
                        "ID",
                        "Category",
                        "Quiz ID",
                        "Total Players",
                        "Joined Players",
                        "Available Slots",
                        "Expiry Time",
                        "Type"
                    ].map((header) => (
                        <th
                            key={header}
                            class="py-3 px-4 bg-indigo-500 text-white text-sm leading-4 font-medium text-left uppercase tracking-wider"
                        >
                            {header}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody class="bg-white">
                {pools.map((pool, index) => (
                    <tr
                        key={pool._id}
                        className={`hover:bg-indigo-100 transition ease-in-out duration-150 ${index % 2 === 0 ? "bg-indigo-50" : ""}`}
                    >
                        <td class="px-4 py-3 text-sm text-gray-700">{pool._id}</td>
                        <td class="px-4 py-3 text-sm text-gray-700">{pool.category}</td>
                        <td class="px-4 py-3 text-sm text-gray-700">{pool.quizid}</td>
                        <td class="px-4 py-3 text-sm text-gray-700">{pool.totalPlayer}</td>
                        <td class="px-4 py-3 text-sm text-gray-700">{pool.joinedPlayer}</td>
                        <td class="px-4 py-3 text-sm text-gray-700">{pool.totalPlayer - pool.joinedPlayer}</td>
                        <td class="px-4 py-3 text-sm text-gray-700">{new Date(pool.expiryTime).toLocaleString()}</td>
                        <td class="px-4 py-3 text-sm text-gray-700">{pool.type}</td>

                    </tr>
                ))}
            </tbody>
        </table>
    </div>
</div>


        <div class="flex flex-row items-center flex-wrap mx-4 md:mx-8 my-4 md:my-8 w-full rounded-lg shadow-lg bg-white">
          <h2
            class="text-3xl font-bold mt-4 mb-8 w-full text-center text-white 
    bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 
    rounded-md p-4 shadow-md
    tracking-wider 
    shadow-text"
          >
            Metrices
          </h2>

          <div class="w-full md:w-1/3 p-4">
            <h3
              class="text-2xl font-bold text-center mt-4 mb-8 text-white 
    bg-gradient-to-r from-blue-400 to-blue-600
    rounded-lg p-4 shadow-sm
    tracking-wider"
            >
              Signup Metrics
            </h3>

            <div className="chart-container">
              <Line
                data={{
                  labels: metricsData.map((metric) => metric.createdAt),
                  datasets: [
                    {
                      label: "Signup Count",
                      data: metricsData.map((metric) => metric.count),
                      fill: false,
                      borderColor: "blue",
                    },
                  ],
                }}
              />
            </div>
            {
              <table
                className="mx-4 md:mx-8 my-4 md:my-8 rounded-lg shadow-lg bg-white border border-gray-300 overflow-hidden"
                style={{ zoom: 0.7 }}
              >
                <thead className="bg-blue-500">
                  <tr>
                    {/* Metric Name heading */}
                    <th className="px-4 py-2 font-bold text-lg text-white">
                      Created At
                    </th>
                    {/* Metric Value heading */}
                    <th className="px-4 py-2 font-bold text-lg text-white">
                      Count
                    </th>
                  </tr>
                </thead>
                {
                  <tbody>
                    {metricsData?.map((metric, index) => (
                      <tr
                        key={metric.createdAt}
                        className={`border-b border-gray-200 ${
                          index % 2 === 0 ? "bg-gray-100" : ""
                        } hover:bg-gray-200`}
                      >
                        {/* Metric Name column */}
                        <td className="px-4 py-2 text-lg font-semibold text-gray-800">
                          {metric.createdAt}
                        </td>
                        {/* Metric Value column */}
                        <td className="px-4 py-2 text-lg font-semibold text-gray-800">
                          {metric.count}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                }
              </table>
            }
          </div>

          <div class="w-full md:w-1/3 p-4">
            <h3
              class="text-2xl font-bold text-center mt-4 mb-8 text-white 
    bg-gradient-to-r from-blue-400 to-blue-600
    rounded-lg p-4 shadow-sm
    tracking-wider"
            >
              Pool Played Metrics
            </h3>

            <div className="chart-container">
              <Line
                data={{
                  labels: poolmetrics.map((metric) => metric.createdAt),
                  datasets: [
                    {
                      label: "Pool Played Count",
                      data: poolmetrics.map((metric) => metric.count),
                      fill: false,
                      borderColor: "green",
                    },
                  ],
                }}
              />
            </div>
            {
              <table
                className="mx-4 md:mx-8 my-4 md:my-8 rounded-lg shadow-lg bg-white border border-gray-300 overflow-hidden"
                style={{ zoom: 0.7 }}
              >
                <thead className="bg-blue-500">
                  <tr>
                    {/* Metric Name heading */}
                    <th className="px-4 py-2 font-bold text-lg text-white">
                      Created At
                    </th>
                    {/* Metric Value heading */}
                    <th className="px-4 py-2 font-bold text-lg text-white">
                      Count
                    </th>
                  </tr>
                </thead>
                {
                  <tbody>
                    {poolmetrics.map((metric, index) => (
                      <tr
                        key={metric.createdAt}
                        className={`border-b border-gray-200 ${
                          index % 2 === 0 ? "bg-gray-100" : ""
                        } hover:bg-gray-200`}
                      >
                        {/* Metric Name column */}
                        <td className="px-4 py-2 text-lg font-semibold text-gray-800">
                          {metric.createdAt}
                        </td>
                        {/* Metric Value column */}
                        <td className="px-4 py-2 text-lg font-semibold text-gray-800">
                          {metric.count}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                }
              </table>
            }
          </div>

          <div class="w-full md:w-1/3 p-4">
            <h3
              class="text-2xl font-bold text-center mt-4 mb-8 text-white 
    bg-gradient-to-r from-blue-400 to-blue-600
    rounded-lg p-4 shadow-sm
    tracking-wider"
            >
              Daily App Open Metrics
            </h3>

            <div className="chart-container">
              <Line
                data={{
                  labels: homepagemetrics.map((metric) => metric.createdAt),
                  datasets: [
                    {
                      label: "App Open Count",
                      data: homepagemetrics.map((metric) => metric.count),
                      fill: false,
                      borderColor: "purple",
                    },
                  ],
                }}
              />
            </div>

            {
              <table
                className="mx-4 md:mx-8 my-4 md:my-8 rounded-lg shadow-lg bg-white border border-gray-300 overflow-hidden"
                style={{ zoom: 0.7 }}
              >
                <thead className="bg-blue-500">
                  <tr>
                    {/* Metric Name heading */}
                    <th className="px-4 py-2 font-bold text-lg text-white">
                      Created At
                    </th>
                    {/* Metric Value heading */}
                    <th className="px-4 py-2 font-bold text-lg text-white">
                      Count
                    </th>
                  </tr>
                </thead>
                {
                  <tbody>
                    {homepagemetrics.map((metric, index) => (
                      <tr
                        key={metric.createdAt}
                        className={`border-b border-gray-200 ${
                          index % 2 === 0 ? "bg-gray-100" : ""
                        } hover:bg-gray-200`}
                      >
                        {/* Metric Name column */}
                        <td className="px-4 py-2 text-lg font-semibold text-gray-800">
                          {metric.createdAt}
                        </td>
                        {/* Metric Value column */}
                        <td className="px-4 py-2 text-lg font-semibold text-gray-800">
                          {metric.count}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                }
              </table>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
