import React, { useState, useEffect } from "react";
import axios from "axios";

const GetByDecreasingCoin = () => {
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        "https://new.quizwalla.in/api/v2/admin/users/coins/20",
        {
          headers: {
            "qw-header":
              "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a",
          },
        }
      );
      setUsers(response.data.users);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div>
      <table
        className="mt-8 w-full bg-white border border-gray-200 transform scale-90"
        style={{ zoom: 0.5 }}
      >
        <thead>
          <tr>
            <th className="px-4 py-2 font-bold bg-gray-100 border-b">
              User ID
            </th>
            <th className="px-4 py-2 font-bold bg-gray-100 border-b">
              Name
            </th>
            <th className="px-4 py-2 font-bold bg-gray-100 border-b">Mobile</th>
            <th className="px-4 py-2 font-bold bg-gray-100 border-b">Coins</th>
            <th className="px-4 py-2 font-bold bg-gray-100 border-b">
              Referral Coins
            </th>
            <th className="px-4 py-2 font-bold bg-gray-100 border-b">
              Can Withdraw Coins
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user._id} className="hover:bg-gray-100">
              <td className="px-6 py-4 border-b text-center">{user._id}</td>
              <td className="px-6 py-4 border-b text-center">{user.name}</td>

              <td className="px-6 py-4 border-b text-center">{user.mobile}</td>
              <td className="px-4 py-2 border-b text-center text-lg text-blue-600">
                {user.walletCredentials.coins}
              </td>
              <td className="px-4 py-2 border-b text-center text-lg text-green-600">
                {user.walletCredentials.referralCoins}
              </td>
              <td className="px-4 py-2 border-b text-center">
                {user.walletCredentials.totalEarnedCoins}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GetByDecreasingCoin;
