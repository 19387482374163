import React, { useState, useEffect } from "react";
import axios from "axios";

function GetUserUsingMobile() {
  const [users, setUsers] = useState([]);
  const [searchMobile, setSearchMobile] = useState("");
  const [searchedUsers, setSearchedUsers] = useState([]);


  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `https://new.quizwalla.in/api/v2/admin/users/${searchMobile}`,
          {
            headers: {
              "qw-header": "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a"
            }
          }
        );
        console.log(response.data)
        setUsers(response.data.user);
        setSearchedUsers(response.data.user);
      } catch (error) {
        console.log(error);
      }
    };

    fetchUsers();
  }, [searchMobile]);

  const handleSearch = () => {
    setSearchedUsers(users.filter((user) => user.mobile === searchMobile));
  };

  const getAggregate = () => {
    const totalPoolJoined = searchedUsers.reduce((a, b) => a + b.poolEntries.length, 0);
    return totalPoolJoined;
  };
  

  return (
    <div>
      <div className="my-4">
        <input
        style={{ zoom: 0.7}}
          type="text"
          value={searchMobile}
          onChange={(e) => setSearchMobile(e.target.value)}
          placeholder="Search by mobile number"
          className="px-4 py-2 border rounded-md"
        />
        <button
        style={{ zoom: 0.7}}
          onClick={handleSearch}
          className="px-4 py-2 ml-2 bg-blue-500 hover:bg-blue-700 text-white rounded-md"
        >
          Search
        </button>
      </div>

      <table className="table-auto m-10 bg-white shadow-lg" style={{ zoom: 0.62}}>
        <thead>
          <tr>
          <th className="px-4 py-2 font-bold border">uid</th>
            <th className="px-4 py-2 font-bold border">Name</th>
            <th className="px-4 py-2 font-bold border">Mobile</th>
            <th className="px-4 py-2 font-bold border">Coins</th>
            <th className="px-4 py-2 font-bold border">Score</th>
            <th className="px-4 py-2 font-bold border">Avatar</th>
            <th className="px-4 py-2 font-bold border">Created At</th>
           

            <th className="px-4 py-2 font-bold border">accountNumber</th>
            <th className="px-4 py-2 font-bold border">canWithdrawCoins</th>
            <th className="px-4 py-2 font-bold border">ifsc</th>
            <th className="px-4 py-2 font-bold border">pan</th>
            <th className="px-4 py-2 font-bold border">poolEntries</th>
            <th className="px-4 py-2 font-bold border">referralCoins</th>
            <th className="px-4 py-2 font-bold border">upi</th>
     

          </tr>
        </thead>
        <tbody>
          {searchedUsers.map((user) => (
            <tr key={user._id}>
              <td className="px-4 py-2 border">{user?.uid}</td>
              <td className="px-4 py-2 border">{user.name}</td>
              <td className="px-4 py-2 border">{user.mobile}</td>
              <td className="px-4 py-2 border">{user.coins}</td>
              <td className="px-4 py-2 border">{user.score}</td>
              <td className="px-4 py-2 border">
                <img
                  src={user.avatar}
                  alt="Avatar"
                  className="w-10 h-10 rounded-full"
                />
              </td>
              <td className="px-4 py-2 border"> {new Date(user?.createdAt).toLocaleString()}</td>
            
              <td className="px-4 py-2 border">{user?.accountNumber}</td>
              <td className="px-4 py-2 border">{user?.canWithdrawCoins}</td>
              <td className="px-4 py-2 border">{user?.ifsc}</td>
              <td className="px-4 py-2 border">{user?.pan}</td>

              <td className="px-4 py-2 border"> {user.poolEntries.join(', ')}</td>
              <td className="px-4 py-2 border">{user?.referralCoins}</td>
              <td className="px-4 py-2 border">{user?.upi}</td>

            </tr>
          ))}
        </tbody>
      </table>
      <div class="mt-8">
  <h4 class="text-xl font-bold text-gray-900 text-center">Total Pool Joined</h4>
  <h5 class="text-lg font-semibold text-gray-500 text-center">
    {getAggregate()}
  </h5>
  <div class="text-center">
    <div class="flex justify-center">
      <div class="text-4xl font-bold text-gray-900">
        <b>{getAggregate()}</b>
      </div>
      <div class="text-2xl font-bold text-gray-500">
        <b>Pools</b>
      </div>
    </div>
    <div class="h-10 w-10 rounded-full bg-gray-300 mx-auto"></div>
  </div>
</div>

    </div>
  );
}

export default GetUserUsingMobile;
