import React from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useLocation } from "react-router-dom";

const UpdateCategory = () => {
  const location = useLocation();
  const qsn1 = location.state;

  const formik = useFormik({
    initialValues: {
      id: qsn1._id,
      name: qsn1.name,
      isUnlisted:qsn1.isUnlisted
    },
    onSubmit: (values) => {
      // Body create
      let apiBody = {
        ...values,
      };

      console.log("apiBody ", apiBody);

      // doing api call
      var config = {
        method: "post",
        url: "https://new.quizwalla.in/api/v2/admin/category/update",
        headers: {
          "Content-Type": "application/json",
          "qw-header":
            "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a",
        },
        data: JSON.stringify({ id: qsn1._id, ...values }),
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          alert("category updated");
        })
        .catch(function (error) {
          alert("something went wrong");

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    },
  });
  return (
    <div class=" flex flex-col justify-center items-center border-2 bg-gradient-to-l from-red-200 via-red-300 to-yellow-200 py-8 px-6 shadow rounded-lg sm:px-10 ">
      <form
        class="w-full max-w-lg font-bold text-1xl text-white outline-4 "
        onSubmit={formik.handleSubmit}
      >
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="id"
            >
              id
            </label>
          </div>
          <div class="md:w-2/3">
            <input
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="text"
              id="id"
              name="id"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.id}
              placeholder="id"
              readOnly
            />
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="title"
            >
              Name
            </label>
          </div>
          <div class="md:w-2/3">
            <input
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="text"
              id="name"
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              placeholder="name"
            />
          </div>
          
        </div>

        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="title"
            >
              isUnlisted
            </label>
          </div>
          <div class="md:w-2/3">
            <select
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="text"
              id="isUnlisted"
              name="isUnlisted"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.isUnlisted}
              
            >
               <option value="true">true</option>
  <option value="false">false</option>
  </select>

          </div>
          
        </div>

        <button
          className=" ml-60 shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded "
          type="submit"
        >
          Update Quiz
        </button>
      </form>
    </div>
  );
};
export default UpdateCategory;
