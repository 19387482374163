import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AllQuiz = () => {
  let navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [showDescription, setShowDescription] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [startIndex, setStartIndex] = useState(1); // Track the start index for the current page

  const fetchFunction = async () => {
    await axios
      .get(
        `https://new.quizwalla.in/api/v2/admin/quiz?page=${page}&itemsPerPage=${itemsPerPage}`,

        {
          headers: {
            "qw-header":
              "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setUser(response.data?.quizzes);
        setStartIndex((page - 1) * itemsPerPage + 1); // Update the start index based on the current page and items per page
      });
  };
  useEffect(() => {
    fetchFunction();

    return () => {
      fetchFunction();
    };
  }, [page, itemsPerPage]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
  };

  const deleteQuestionHandler = (quizid) => {
    console.log("deletequiz ", quizid);

    // Body create
    let apiBody = {
      id: quizid,
    };

    // doing api call
    var config = {
      method: "delete",
      url: "https://new.quizwalla.in/api/v2/admin/quiz/delete",
      headers: {
        "Content-Type": "application/json",
        "qw-header":
          "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a",
      },
      data: JSON.stringify(apiBody),
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        alert("quiz deleted");
      })
      .catch(function (error) {
        alert("something went wrong");

        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  };

  console.log("USER =>", user);

  if (user?.length) {
    return (
      <div className="flex flex-col">
        {/* <Header category="Page" title="All quiz" /> */}
        <div className="overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <div className="overflow-scroll border rounded-lg">
              <table
                className="min-w-full divide-y divide-gray-400 rounded-3xl border-8"
                style={{ zoom: 0.5 }}
              >
                <thead className="bg-red-50">
                  <tr>
                    <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Serial
                    </th>
                    <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      id
                    </th>
                    <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      title
                    </th>
                    <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      description
                    </th>
                    <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      categoryName
                    </th>
                    <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      numberOfQuestions
                    </th>
                    <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      time
                    </th>
                    <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      tags
                    </th>
                    <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      isUnlisted
                    </th>
                    <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      createdAt
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {user.map((res, index) => {
                    return (
                      <tr className="bg-white hover:bg-gray-100" key={index}>
                        <td className="px-4 py-2 border-2  hover:bg-fuchsia-100">
                          {startIndex + index}
                        </td>
                        <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                          {res?._id}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-wrap break-normal ">
                          {res?.title}
                        </td>

                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-wrap break-normal">
                          <button
                            type="button"
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={() => {
                              setShowDescription(!showDescription);
                            }}
                          >
                            {showDescription ? res?.description : ""}
                          </button>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          {res?.category}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">{10}</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {res?.time}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex flex-wrap gap-2">
                            <span
                              key={index}
                              className={
                                "inline-block px-3 py-1 bg-purple-400 text-black rounded-full text-sm "
                              }
                            >
                              {res.tags}
                            </span>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                        {res.isUnlisted ? "true" : "false"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {new Date(res?.createdAt).toLocaleString()}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap whitespace-wrap break-normal">
                          <button
                            className="shadow bg-red-700 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                            onClick={(e) => {
                              e.preventDefault();
                              deleteQuestionHandler(res._id);
                            }}
                          >
                            quiz delete
                          </button>
                        </td>

                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap whitespace-wrap break-normal">
                          <button
                            className="shadow bg-orange-400 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                            onClick={(e) => {
                              e.preventDefault();
                              navigate("/updatequiz", { state: res });
                            }}
                          >
                            update quiz
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="flex justify-center mt-4">
          <button
            style={{ zoom: 0.7 }}
            onClick={(event) => handlePageChange(event, page - 1)}
            disabled={page === 1}
            className="p-2 rounded-full bg-gray-300 hover:bg-gray-400 focus:outline-none focus:shadow-outline text-blue-500 -mx-2 -my-2 inline-block"
          >
            Previous
          </button>
          <div className="mx-2"></div>
          <button
            style={{ zoom: 0.7 }}
            onClick={(event) => handlePageChange(event, page + 1)}
            disabled={user?.length < itemsPerPage}
            className="p-2 rounded-full bg-gray-300 hover:bg-gray-400 focus:outline-none focus:shadow-outline text-blue-500 -mx-2 -my-2 inline-block"
          >
            Next
          </button>
        </div>

        <div className="flex justify-center mt-4">
          <label className="mx-2 font-bold" style={{ zoom: 0.7 }}>
            Items per page:
          </label>
          <select
            style={{ zoom: 0.7 }}
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            className="p-2 bg-gray-300 rounded-full hover:bg-gray-400 focus:outline-none focus:shadow-outline -mx-2 -my-2 inline-block"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
          </select>
        </div>
      </div>
    );
  }
};

export default AllQuiz;
