import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";

const Login = () => {
  const { activeMenu, setActiveMenu, screenSize } = useStateContext();

  setActiveMenu(false);

  let navigate = useNavigate();

  const loginHandler = () => {
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;

    // Body create
    let apiBody = {
      email,
      password,
    };

    // doing api call
    var config = {
      method: "post",
      url: "https://new.quizwalla.in/api/v2/admin/login",
      headers: {
        "Content-Type": "application/json",
        "qw-header": "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a",
      },
      data: JSON.stringify(apiBody),
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        alert("login done");
        localStorage.setItem('Token', 'ujfh8h76i6jg7ok80lpko7i6juy64tg4dyhtu6kll7;m[0-;');
        if(response.data.email === "qzroleAdmin@gmail.com"){
          localStorage.setItem('Role', 'rolebasedaccess');
        }
        navigate("/dashboard");
        setActiveMenu(true);
      })
      .catch(function (error) {
        alert("something went wrong");

        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 ">
      <div className="hidden sm:block h-screen w-full">
        <img
          className="w-full h-full object-cover"
          src="https://source.unsplash.com/random"
          alt=""
        />
      </div>
      <div className="bg-gray-800 flex flex-col justify-center">
        <form className="max-w-[400px] w-full mx-auto bg-gray-900 p-8 px-8 rounded-lg">
        <div className="text-center mb-4">
                        <img 
                            src="/quizwalla logo.png" 
                            alt="Quiz Walla Logo" 
                            className="mx-auto w-1/2"
                        />
                    </div>
          <div className="flex flex-col text-gray-400 py-2">
            <label>Email</label>
            <input
              className="rounded-lg bg-gray-700 mt-2 p-2 focus:border-value-500 focus:bg-gray-500 focus:outline-none"
              type="email"
              name="email"
              id="email"
              required
            />
          </div>
          <div className="flex flex-col text-gray-400 py-2">
            <label>Password</label>
            <input
              className="rounded-lg bg-gray-700 mt-2 p-2 focus:border-value-500 focus:bg-gray-500 focus:outline-none"
              type="password"
              name="password"
              id="password"
              required
            />
          </div>
          <button
            className="w-full my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/80 hover:shadow-teal-500/40 text-white font-semibold rounded-lg"
            onClick={(e) => {
              e.preventDefault();
              loginHandler();
            }}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login; 