import React from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useLocation } from "react-router-dom";

function UpdateQuestion() {

  const firstOptionValue = document.getElementById("firstoption")
  ? document.getElementById("firstoption").value
  : "";
const secondOptionValue = document.getElementById("secondoption")
  ? document.getElementById("secondoption").value
  : "";
const thirdOptionValue = document.getElementById("thirdoption")
  ? document.getElementById("thirdoption").value
  : "";
const fourthOptionValue = document.getElementById("fourthoption")
  ? document.getElementById("fourthoption").value
  : "";

  const location = useLocation();
  const qsn = location.state;
  const formik = useFormik({
    initialValues: {
      question: qsn.question,
      description: qsn.description,
      category: qsn.category,
      difficulty: qsn.difficulty,
      tags: qsn.tags,
      options:qsn.options,
      correctOption: qsn.correctOption,
      isUnlisted:qsn.isUnlisted
    },
    onSubmit: async (values) => {
      console.log("values", values);

      // doing api call
      var config = {
        method: "post",
        url: "https://new.quizwalla.in/api/v2/admin/question/update",
        headers: {
          "Content-Type": "application/json",
          "qw-header":
            "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a",
        },
        data: JSON.stringify({ id: qsn._id, ...values , tags:values.tags.includes(",")?values.tags?.split(","):values.tags}),
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          alert("question updated");
        })
        .catch(function (error) {
          alert("something went wrong");

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    },
  });

  return (
    <div className=" flex flex-col justify-center items-center border-2 bg-gradient-to-r from-lime-600 via-yellow-300 to-red-600 py-8 px-6 shadow rounded-lg sm:px-10 ">
      <form
        className="w-full max-w-lg font-bold text-1xl text-white outline-4 "
        onSubmit={formik.handleSubmit}
      >
        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="question"
            >
              Question
            </label>
          </div>

          <div className="md:w-2/3">
            <textarea
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-10 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="text"
              id="question"
              name="question"
              onChange={formik.handleChange}
              value={formik.values.question}
              placeholder="question"
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="expirytime"
            >
              Tags<br></br>
            </label>
          </div>

          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="tags"
              name="tags"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.tags}
              placeholder="linux,os,kernel"
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="description"
            >
              Description
            </label>
          </div>
          <div className="md:w-2/3">
            <textarea
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="text"
              id="description"
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
              placeholder="description"
            />
          </div>
        </div>

        
        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="firstoption"
            >
              First Option
            </label>
          </div>

          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="firstoption"
              name="options[0]"
              onChange={formik.handleChange}
              value={formik.values.options.join(",").split(",")[0]}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="secondoption"
            >
              Second Option
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="secondoption"
              name="options[1]"
              onChange={formik.handleChange}
              value={formik.values.options.join(",").split(",")[1]}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="thirdoption"
            >
              Third Option
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="thirdoption"
              name="options[2]"
              onChange={formik.handleChange}
              value={formik.values.options.join(",").split(",")[2]}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="fourthoption"
            >
              Fourth Option
            </label>
          </div>

          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="fourthoption"
              name="options[3]"
              onChange={formik.handleChange}
              value={formik.values.options.join(",").split(",")[3]}
            ></input>
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="correctOption"
            >
              CorrectOption<br></br>
            </label>
          </div>

          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="correctOption"
              name="correctOption"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.correctOption}
            />
          </div>
        </div>


        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="category"
            >
              Category
            </label>
          </div>

          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="text"
              id="category"
              name="category"
              onChange={formik.handleChange}
              value={formik.values.category}
              placeholder="category"
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="difficulty"
            >
              Difficulty
            </label>
          </div>

          <div className="md:w-2/3">
            <select
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              name="difficulty"
              onChange={formik.handleChange}
            >
              <option value="">Select level</option>
              <option value="EASY">EASY</option>
              <option value="MEDIUM">MEDIUM</option>
              <option value="HARD">HARD</option>
            </select>
          </div>
        </div>

        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="title"
            >
              isUnlisted
            </label>
          </div>
          <div class="md:w-2/3">
            <select
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="text"
              id="isUnlisted"
              name="isUnlisted"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.isUnlisted}
              
            >
               <option value="true">true</option>
  <option value="false">false</option>
  </select>

          </div>
          
        </div>

        <button
          className=" ml-60 shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded "
          type="submit"
        >
          Update Question
        </button>
      </form>
    </div>
  );
}
export default UpdateQuestion;
