import React, { useState, useEffect } from "react";

import axios from "axios";

function RejectedWithdrawal() {
  
  const [created, setCreated] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [startIndex, setStartIndex] = useState(1); // Track the start index for the current page


  const fetchFunction = async () => {
    await axios
      .get(`https://new.quizwalla.in/api/v2/admin/rejected-withdrawal?page=${page}&itemsPerPage=${itemsPerPage}`,
      {
        headers: {
          "qw-header": "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a",
        },
      }
    )
      .then((response) => {
        console.log(response.data);
        setCreated(response.data?.rejected);
      setStartIndex((page - 1) * itemsPerPage + 1); // Update the start index based on the current page and items per page

      });
  };
  useEffect(() => {
    fetchFunction();
  }, [page, itemsPerPage]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
  };


  return (
    <div>
      <table className="table-auto m-10"  style={{ zoom: 0.6}}>
        <thead>
          <tr>
          <th className="px-4 py-2 font-bold border-2">serial</th>

            <th className="px-4 py-2 font-bold border-2">Id</th>
            <th className="px-4 py-2 font-bold border-2">Timestamp</th>
            <th className="px-4 py-2 font-bold border-2">Name</th>
            <th className="px-4 py-2 font-bold border-2">Upi</th>
            <th className="px-4 py-2 font-bold border-2">Ifsc</th>
            <th className="px-4 py-2 font-bold border-2">Account Number</th>
            <th className="px-4 py-2 font-bold border-2">Current status</th>
            <th className="px-4 py-2 font-bold border-2">Remark</th>
           
          </tr>
        </thead>
        <tbody>
          {created?.map((res, index) => {
            return (
              <tr key={res._id}  className="bg-white hover:bg-gray-100">
                <td className="px-4 py-2 border-2  hover:bg-fuchsia-100">
                        {startIndex + index}
                        
                      </td>
                <td className="px-4 py-2 border-2">{res._id}</td>
                <td className="px-4 py-2 border-2">{res.createdAt}</td>
                <td className="px-4 py-2 border-2">{res.name}</td>
                <td className="px-4 py-2 border-2">{res.upi}</td>
                <td className="px-4 py-2 border-2">{res.ifsc}</td>
                <td className="px-4 py-2 border-2">{res.accountNumber}</td>
                <td className="px-4 py-2 border-2">{res.status}</td>
                <td className="px-4 py-2 border-2">{res.remark}</td>
             
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="flex justify-center mt-4">
  <button
  style={{ zoom: 0.7 }}
    onClick={(event) => handlePageChange(event, page - 1)}
    disabled={page === 1}
    className="p-2 rounded-full bg-gray-300 hover:bg-gray-400 focus:outline-none focus:shadow-outline text-blue-500 -mx-2 -my-2 inline-block"
  >
    Previous
  </button>
  <div className="mx-2"></div>
  <button
  style={{ zoom: 0.7 }}
    onClick={(event) => handlePageChange(event, page + 1)}
    disabled={created?.length < itemsPerPage}
    className="p-2 rounded-full bg-gray-300 hover:bg-gray-400 focus:outline-none focus:shadow-outline text-blue-500 -mx-2 -my-2 inline-block"
  >
    Next
  </button>
</div>


<div className="flex justify-center mt-4">
  <label className="mx-2 font-bold" style={{ zoom: 0.7 }}>Items per page:</label>
  <select
  style={{ zoom: 0.7 }}
    value={itemsPerPage}
    onChange={handleItemsPerPageChange}
    className="p-2 bg-gray-300 rounded-full hover:bg-gray-400 focus:outline-none focus:shadow-outline -mx-2 -my-2 inline-block"
  >
    <option value={10}>10</option>
    <option value={20}>20</option>
    <option value={50}>50</option>
    <option value={100}>100</option>
  </select>
</div>

     
    </div>
  );
}

export default RejectedWithdrawal ;
