import React, { Component, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FiSettings } from "react-icons/fi";

import { Navbar, Footer, Sidebar, ThemeSetting, Login } from "./component";
import {
  Dashboard,
  AllCategory,
  Calendar,
  AllUser,
  AllQuiz,
  CreateQuestion,
  CreateQuiz,
  CreateCateg,
  CreatePool,
  CreateSinglePool,
  AllPool,
  AllQuestion,
  AllSinglePool,
  UpdateCoin,
  Bank,
  Bank2,
  AcceptedWithdrawal,
  RejectedWithdrawal,
  AcceptedAdd,
  RejectedAdd,
  Account,
  UpdateQuestion,
  UpdateQuiz,
  AllDeposit,
  UserHistory,
  ExpiredPool,
  AppConfig,
  OfferNotification,
  GetByDecreasingCoin,
  GetUserUsingMobile,
  GetAllPoolJoinedPlayer,
  ReportedPool,
  ReportedSinglePool,
  UserHistory2,
  Notification,
  UpdateCategory,
  NumberOfBots,
} from "./pages";

import { useStateContext } from "./contexts/ContextProvider";

import "./App.css";

const App = () => {
  const { activeMenu } = useStateContext();
  const userToken = localStorage.getItem("Token");
  const role = localStorage.getItem("Role");

  return (
    <div>
      <BrowserRouter>
        {!userToken ? (
          <div>
            <Routes>
              <Route path="/" element={<Login />} />
            </Routes>
          </div>
        ) : (
          <div className="flex relative dark:bg-main-dark-bg">
            <div
              className="fixed right-4 bottom-4"
              style={{ zIndex: "1000" }}
            ></div>
            {activeMenu ? (
              <div className="w-65 fixed sidebar dark:bg-secondary-dark-bg bg-white">
                <Sidebar />
              </div>
            ) : (
              <div className="w-0 dark:bg-secondary-dark-bg">
                <Sidebar />
              </div>
            )}

            <div
              className={`dark:bg-main-bg bg-main-bg min-h-screen w-full ${
                activeMenu ? "md:ml-72" : "flex-2"
              }`}
            >
              <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
                <Navbar />
              </div>
              {!role ? (
                <div>
                  <Routes>
                    {/* dasboard */}
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/dashboard" element={<Dashboard />} />

                    {/* pages */}

                    <Route path="/allcategory" element={<AllCategory />} />
                    <Route path="/alluser" element={<AllUser />} />
                    <Route path="/allquiz" element={<AllQuiz />} />
                    <Route
                      path="/createquestion"
                      element={<CreateQuestion />}
                    />
                    <Route path="/createcateg" element={<CreateCateg />} />
                    <Route path="/createpool" element={<CreatePool />} />
                    {/* <Route path="/createsinglepool" element={<CreateSinglePool />} /> */}

                    <Route path="/createquiz" element={<CreateQuiz />} />
                    <Route path="/allquestion" element={<AllQuestion />} />
                    <Route path="/allpool" element={<AllPool />} />
                    {/* <Route path="/allsinglepool" element={<AllSinglePool />} /> */}
                    <Route path="/alldeposit" element={<AllDeposit />} />

           
                    <Route path="/updatecoin" element={<UpdateCoin />} />
                    <Route
                      path="/updatequestion"
                      element={<UpdateQuestion />}
                    />
                    <Route path="/updatequiz" element={<UpdateQuiz />} />
                    <Route
                      path="/updatecategory"
                      element={<UpdateCategory />}
                    />

                    <Route path="/CreatedWithdrawal" element={<Bank />} />
                    {/* <Route path="/CreatedAdd" element={<Bank2 />} /> */}

                    <Route
                      path="/AcceptedWithdrawal"
                      element={<AcceptedWithdrawal />}
                    />
                    <Route
                      path="/RejectedWithdrawal"
                      element={<RejectedWithdrawal />}
                    />
                    {/* <Route
                      path="/AcceptedAdd"
                      element={<AcceptedAdd />}
                    /> */}
                    {/* <Route
                      path="/RejectedAdd"
                      element={<RejectedAdd />}
                    /> */}
                    {/* <Route path="/Account" element={<Account />} /> */}
                    {/* <Route path="/UserHistory" element={<UserHistory/>} /> */}
                    {/* <Route path="/ExpiredPool" element={<ExpiredPool/>} /> */}
                    <Route path="/AppConfig" element={<AppConfig />} />
                    {/* <Route path="/OfferNotification" element={<OfferNotification/>} /> */}
                    <Route
                      path="/GetByDecreasingCoin"
                      element={<GetByDecreasingCoin />}
                    />
                    {/* <Route
                      path="/GetUserUsingMobile"
                      element={<GetUserUsingMobile />}
                    /> */}
                    {/* <Route path="/GetAllPoolJoinedPlayer" element={<GetAllPoolJoinedPlayer/>} /> */}
                    <Route path="/ReportedPool" element={<ReportedPool />} />
                    <Route path="/UserHistory2" element={<UserHistory2 />} />
                    <Route path="/Notification" element={<Notification />} />
                    <Route path="/NumberOfBots" element={<NumberOfBots />} />

                    {/* <Route path="/ReportedSinglePool" element={<ReportedSinglePool/>} /> */}

                   

               

                    {/* login */}
                    {/* <Route path="/login" element={<Login />} /> */}
                  </Routes>
                </div>
              ) : (
                <div>
                  <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />
             
                    <Route path="/allcategory" element={<AllCategory />} />
                    <Route path="/allpool" element={<AllPool />} />
                    {/* <Route path="/allsinglepool" element={<AllSinglePool />} /> */}
                    {/* <Route path="/alldeposit" element={<AllDeposit />} /> */}

                    <Route path="/allquiz" element={<AllQuiz />} />
                    <Route path="/allquestion" element={<AllQuestion />} />
                    <Route path="/createcateg" element={<CreateCateg />} />
                    <Route path="/createpool" element={<CreatePool />} />
                    {/* <Route path="/createpool" element={<CreateSinglePool />} /> */}

                    <Route path="/createquiz" element={<CreateQuiz />} />
                    {/* <Route
                      path="/updatequestion"
                      element={<UpdateQuestion />}
                    /> */}
                    <Route
                      path="/createquestion"
                      element={<CreateQuestion />}
                    />

                    {/* <Route path="/calendar" element={<Calendar />} /> */}
                    {/* <Route path="/alluser" element={<AllUser />} /> */}
                  </Routes>
                </div>
              )}
            </div>
          </div>
        )}
      </BrowserRouter>
    </div>
  );
};

export default App;
