import React, { useState, useEffect } from "react";
import axios from "axios";

const NumberOfBots = () => {
  const [limit, setLimit] = useState("");
  const [response, setResponse] = useState("");
  const [botCount, setBotCount] = useState(0);


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://new.quizwalla.in/api/v2/admin/create-bot",
        { limit },
        {
          headers: {
            "qw-header":
              "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a",
          },
        }
      );

      setResponse(JSON.stringify(response.data));
    } catch (error) {
      console.error("Error:", error);
      setResponse("Error occurred. Check the console for details.");
    }
  };

  useEffect(() => {
    // Fetch the number of bots using a GET request
    axios
      .get("https://new.quizwalla.in/api/v2/admin/bot", {
        headers: {
          "qw-header":
            "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a",
        },
      })
      .then((response) => {
        setBotCount(response.data.bots.length);
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors here
      });
  }, []);

  return (
    <div className="w-1/2 mx-auto">
      <h1 className="text-2xl font-semibold mb-4">Create Bot</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-600 font-semibold">Limit:</label>
          <input
            type="number"
            className="w-full border border-gray-300 rounded py-2 px-3 focus:outline-none focus:border-blue-400"
            value={limit}
            onChange={(e) => setLimit(e.target.value)}
            required
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white rounded px-4 py-2 hover:bg-blue-600"
        >
          Create Bot
        </button>
      </form>
      <div className="mt-4">
        <h2 className="text-lg font-semibold">Response:</h2>
        <pre className="mt-2 bg-gray-200 p-2 rounded">{response}</pre>
      </div>
      <div className="w-1/2 mx-auto mt-8 p-4 border rounded-lg shadow-lg bg-white">
      <h1 className="text-2xl font-semibold mb-4 text-center">Number of Bots</h1>
      <div className="text-4xl font-bold text-center animate-bounce">
        {botCount}
      </div>
    </div>
    </div>
    
  );
};

export default NumberOfBots;
