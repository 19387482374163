import React from "react";
import { useFormik } from "formik";
import axios from "axios";

function AppConfig() {
  const formik = useFormik({
    initialValues: {
      isMaintainace: true,
      minAppVersionCode: 26,
      popup: ''
    },
    onSubmit: async (values) => {
      // Body create
      let apiBody = {
        isMaintainace: values.isMaintainace,
        minAppVersionCode: values.minAppVersionCode,
        popup: values.popup
      };

      // Doing API call
      console.log(JSON.stringify(apiBody));
      var config = {
        method: "post",
        url: "https://new.quizwalla.in/api/v2/auth/app-config",
        headers: {
          "Content-Type": "application/json",
          "qw-header":
            "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a",
        },
        data: JSON.stringify(apiBody),
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data.message));
          alert(response.data.message);
        })
        .catch(function (error) {
          alert("Something went wrong");

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    },
  });

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-indigo-500 to-purple-500">
      <form
        className="bg-white p-8 rounded-lg shadow-md w-full max-w-md"
        onSubmit={formik.handleSubmit}
      >
        <div className="mb-4">
          <label
            htmlFor="isMaintainace"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            isMaintenance
          </label>
          <select
            className="w-full bg-gray-200 border border-gray-300 rounded py-2 px-4 text-gray-700 focus:outline-none focus:border-purple-500"
            name="isMaintainace"
            id="isMaintainace"
            onChange={formik.handleChange}
            value={formik.values.isMaintainace}
          >
            <option value={true}>True</option>
            <option value={false}>False</option>
          </select>
        </div>

        <div className="mb-4">
          <label
            htmlFor="minAppVersionCode"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            minAppVersionCode
          </label>
          <input
            className="w-full bg-gray-200 border border-gray-300 rounded py-2 px-4 text-gray-700 focus:outline-none focus:border-purple-500"
            id="minAppVersionCode"
            name="minAppVersionCode"
            type="number"
            min="26"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.minAppVersionCode}
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="popup"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            popup image link
          </label>
          <input
            className="w-full bg-gray-200 border border-gray-300 rounded py-2 px-4 text-gray-700 focus:outline-none focus:border-purple-500"
            id="popup"
            name="popup"
            type="string"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.popup}
          />
        </div>

        <button
          className="w-full bg-purple-500 hover:bg-purple-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="submit"
        >
          Submit
        </button>
      </form>
    </div>
  );
} 

export default AppConfig;
