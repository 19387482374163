import React, { useState, useEffect } from "react";
import axios from "axios";

const ReportedPool = () => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://new.quizwalla.in/api/v2/admin/pools/reported",
          {
            headers: {
              "qw-header":
                "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a",
            },
          }
        );
        console.log(response.data.reportedPools)
        setTableData(response.data.reportedPools);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  // const handleDeclareResult = async (id) => {
  //   try {
  //     const response = await axios.get(
  //       `https://new.quizwalla.in/api/v2/admin/p/result/declare/${id}`,
  //       {
  //         headers: {
  //           "qw-header":
  //             "53a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62d2c8a",
  //         },
  //       }
  //     );
  //     if (response.status === 200) {
  //       alert(response.data.message)
  //     } else {
  //       console.log(response.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <div className="p-6">
      <table className="table w-full border-collapse border border-gray-300">
        <thead>
          <tr>
            <th className="p-4 text-gray-500 font-semibold text-center">ID</th>
            <th className="p-4 text-gray-500 font-semibold text-center">Quiz ID</th>
            <th className="p-4 text-gray-500 font-semibold text-center">Category</th>
            <th className="p-4 text-gray-500 font-semibold text-center">Reported</th>
            <th className="p-4 text-gray-500 font-semibold text-center">Report Count</th>
            {/* <th className="p-4 text-gray-500 font-semibold text-center">Actions</th> */}
          </tr>
        </thead>
        <tbody>
          {tableData.map((item) => (
            <tr key={item._id} className="border-b border-gray-300 hover:bg-gray-200">
              <td className="p-2 text-center">{item._id}</td>
              <td className="p-2 text-center">{item.quizid}</td>
              <td className="p-2 text-center">{item.category}</td>
              <td className="p-2 text-center">{item.isReported ? "Yes" : "No"}</td>
              <td className="p-2 text-center">{item.reportedCount}</td>
              {/* <td className="p-2 text-center">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                  onClick={() => handleDeclareResult(item._id)}
                >
                  Declare Result
                </button>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReportedPool;
